import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import Button from 'components/Button';
import { StepComponent } from 'components/Steps/stepUtils';
import FormContainer from 'components/LoanForm/FormContainer';
import Input from 'components/Input';
import { getMessageForRequiredFields } from 'utils/errors';
import { StepsResult } from 'enums/FlowNextResults';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { getStudentLoanAssistanceData, updateStudentLoanApplication } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import NumberInput from 'components/NumberInput';
import { ANNUAL_INCOME_MAX_LENGTH } from 'components/LoanForm/YourTotalIncome/YourTotalIncome';
import { IncomeTaxFilingStatus as FilingStatus } from 'api/StudentLoanApi';
import { STATE_OPTIONS } from 'utils/getCountryStateLabel';
import InputSelect from 'components/InputSelect';
import Checkbox from 'components/Checkbox/Checkbox';

import styles from './PersonalDetails.module.scss';

export const FAMILY_SIZE_MAX_LENGTH = 2;

enum Field {
  AdjustedGrossIncome = 'Adjusted gross income',
  IncomeTaxFilingStatus = 'Income tax filing status',
  DriversLicenseState = 'Drivers license state',
  DriversLicenseNumber = 'Drivers license number',
}

enum FieldLabel {
  DriversLicenseState = "Driver's license state",
  DriversLicenseNumber = "Driver's license number",
}

const PersonalDetails = ({ handleNext }: StepComponent) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const {
    applicationId,
    applicationData: { adjustedGrossIncome, incomeTaxFilingStatus, driversLicenseState, driversLicenseNumber },
  } = useSelector(getStudentLoanApplication);

  const [isLoading, setIsLoading] = useState(false);

  const {
    formState: { errors, isValid },
    trigger,
    register,
    watch,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [Field.AdjustedGrossIncome]: adjustedGrossIncome,
      [Field.IncomeTaxFilingStatus]: incomeTaxFilingStatus,
      [Field.DriversLicenseState]: driversLicenseState,
      [Field.DriversLicenseNumber]: driversLicenseNumber,
    },
  });
  const watcher = watch();

  useEffect(() => {
    register(Field.AdjustedGrossIncome, {
      required: getMessageForRequiredFields(Field.AdjustedGrossIncome),
    });
    register(Field.IncomeTaxFilingStatus, {
      required: getMessageForRequiredFields(Field.IncomeTaxFilingStatus),
    });
    register(Field.DriversLicenseState, {
      required: getMessageForRequiredFields(FieldLabel.DriversLicenseState),
    });
    register(Field.DriversLicenseNumber, {
      required: getMessageForRequiredFields(FieldLabel.DriversLicenseNumber),
    });
  }, [register, watcher]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as Field, event.target.value.trim());
    trigger(event.target.name as Field);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as Field, event.target.value);
    trigger(event.target.name as Field);
  };

  const onNext = async () => {
    setIsLoading(true);

    await dispatchWithUnwrap(
      updateStudentLoanApplication({
        applicationId: applicationId!,
        applicationData: {
          adjustedGrossIncome: watcher[Field.AdjustedGrossIncome],
          incomeTaxFilingStatus: watcher[Field.IncomeTaxFilingStatus],
          driversLicenseState: watcher[Field.DriversLicenseState],
          driversLicenseNumber: watcher[Field.DriversLicenseNumber],
        },
      }),
    );

    dispatchWithUnwrap(
      getStudentLoanAssistanceData({
        applicationId: applicationId!,
      }),
    );

    analytics.track('Student Loan Personal Details Submitted');
    handleNext(StepsResult.Completed);
    setIsLoading(false);
  };

  return (
    <FormContainer
      title="Personal Details"
      subtitle="Please correct and enter any missing information for your application."
    >
      <NumberInput
        label={Field.AdjustedGrossIncome}
        prefix="$"
        placeholder="$0"
        errorMessage={errors[Field.AdjustedGrossIncome]?.message}
        thousandSeparator
        name={Field.AdjustedGrossIncome}
        onChange={(event) => {
          setValue(Field.AdjustedGrossIncome, Number(event.target.value.replace(/[^0-9.-]+/g, '')));
          trigger(Field.AdjustedGrossIncome);
        }}
        value={watcher[Field.AdjustedGrossIncome] ? `${watcher[Field.AdjustedGrossIncome]}` : undefined}
        maxLength={ANNUAL_INCOME_MAX_LENGTH}
      />

      <InputSelect
        label={Field.IncomeTaxFilingStatus}
        options={Object.keys(FilingStatus).map((key) => ({
          label: FilingStatus[key as keyof typeof FilingStatus],
          value: FilingStatus[key as keyof typeof FilingStatus],
        }))}
        onChange={(option) => {
          setValue(Field.IncomeTaxFilingStatus, option.value);
          trigger(Field.IncomeTaxFilingStatus);
        }}
        value={watcher[Field.IncomeTaxFilingStatus]}
        placeholder="Select"
        name={Field.IncomeTaxFilingStatus}
      />

      <Checkbox
        label="I have a driver's license"
        checked={Boolean(watcher[Field.DriversLicenseState] && watcher[Field.DriversLicenseState] !== 'N/A')}
        onChange={(event) => {
          const isChecked = event.target.checked;
          if (!isChecked) {
            setValue(Field.DriversLicenseState, 'N/A');
            setValue(Field.DriversLicenseNumber, 'N/A');
            trigger(Field.DriversLicenseState);
            trigger(Field.DriversLicenseNumber);
          }
        }}
      />

      <InputSelect
        label={FieldLabel.DriversLicenseState}
        options={[...STATE_OPTIONS, { label: 'N/A', value: 'N/A' }]}
        onChange={(option) => {
          setValue(Field.DriversLicenseState, option.value);
          trigger(Field.DriversLicenseState);
        }}
        value={watcher[Field.DriversLicenseState]}
        placeholder="Select"
        name={Field.DriversLicenseState}
      />

      <Input
        label={FieldLabel.DriversLicenseNumber}
        placeholder="Enter alphanumeric number"
        errorMessage={errors[Field.DriversLicenseNumber]?.message}
        name={Field.DriversLicenseNumber}
        onBlur={onBlur}
        onChange={onChange}
        value={watcher[Field.DriversLicenseNumber]}
      />

      <Button disabled={!isValid} className={styles.button} onClick={onNext} isLoading={isLoading}>
        Save and Continue
      </Button>
    </FormContainer>
  );
};

export default PersonalDetails;
