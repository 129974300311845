import React, { useState } from 'react';

import { isPossiblePhoneNumber } from 'react-phone-number-input';

import FormContainer from 'components/LoanForm/FormContainer';
import PhoneNumberInput from 'components/PhoneNumberInput';
import { PHONE_NUMBER_LENGTH, YourContactInputLabel } from 'components/LoanForm/YourContact/YourContact';
import { YourContactVariable } from 'enums/LoanFormVariables';
import styles from 'components/FinancialCheckup/PhoneNumber/YourPhoneNumber.module.scss';
import Button from 'components/Button';

export interface EnterPhoneNumberProps {
  onPhoneNumberEntered: (phoneNumber: string) => void;
}

export const EnterPhoneNumber = (args: EnterPhoneNumberProps) => {
  const { onPhoneNumberEntered } = args;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);

  const onChange = (value: string) => {
    let newIsValid;
    if (value) {
      newIsValid = isPossiblePhoneNumber(value);
      // setPhoneNumberIsValid(METHOD_TESTING_PHONES.includes(value as string) || isValidPhoneNumber(value));
    } else {
      newIsValid = false;
    }
    setPhoneNumber(value);
    setPhoneNumberIsValid(newIsValid);
  };

  return (
    <FormContainer title="Enter your phone number" subtitle="We will send you a code to verify your phone number">
      <PhoneNumberInput
        label={YourContactInputLabel.PhoneNumber}
        placeholder="000-000-0000"
        className={styles.formInput}
        name={YourContactVariable.PhoneNumber}
        onChange={onChange}
        value={phoneNumber}
        maxLength={PHONE_NUMBER_LENGTH}
        country="US"
        disabled={false}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
          e.key === 'Enter' && phoneNumberIsValid && onPhoneNumberEntered(phoneNumber)
        }
        autoFocus
      />
      <Button
        className={styles.button}
        disabled={!phoneNumberIsValid}
        onClick={() => onPhoneNumberEntered(phoneNumber)}
      >
        Send Code To My Phone
      </Button>
    </FormContainer>
  );
};
