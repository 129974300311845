import React, { useEffect, useState } from 'react';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { sendSmsCode, sendSmsCodeForApplicationId } from 'thunks';

import { useQueryParams } from 'hooks/useQueryParam';

import { UrlParams } from 'enums/UrlParams';

import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import { ReactComponent as GreenCheck } from 'images/green-check.svg';

import StateContainer from 'components/StateContainer';
import Button from 'components/Button';
import FormContainer from 'components/LoanForm/FormContainer';

import VerifySms from './VerifySms';

import { EnterPhoneNumber } from './EnterPhoneNumber';

import styles from './SmsLogin.module.scss';

enum SmsAuthState {
  WaitForUserClick,
  Start,
  Sending,
  Sent,
  Success,
}

const SmsLogin = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const params = useQueryParams();
  const [authState, setAuthState] = useState(SmsAuthState.WaitForUserClick);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  function getRedirectPath(): string | null {
    return params.get(UrlParams.ComingFrom);
  }

  function getRedirectUrl(): string | null {
    const path = getRedirectPath();
    if (path === null) return null;
    return `${window.location.protocol}//${window.location.host}${getRedirectPath()}`;
  }

  function getApplicationId(): string | null {
    const redirectUrl = getRedirectUrl();
    if (redirectUrl !== null) {
      const comingFrom = new URL(redirectUrl);
      if (comingFrom.searchParams.has(UrlParams.ResumeProcess)) {
        return comingFrom.searchParams.get(UrlParams.ResumeProcess);
      }
    }
    return null;
  }

  const applicationId = getApplicationId();

  const sendSmsByApplicationId = async (appId: string) => {
    await dispatchWithUnwrap(sendSmsCodeForApplicationId({ applicationId: appId }));
  };

  const sendSmsByPhoneNumber = async () => {
    if (phoneNumber === null) {
      throw new Error('Phone number is null');
    }
    console.log('sending sms code for phone number', phoneNumber);
    dispatchWithUnwrap(sendSmsCode({ phoneNumber }));
  };

  const onSuccessfulVerification = () => {
    // We want to navigate to where we came from, but we also need the application to reload
    // so that redirected page makes new backend calls.
    if (getRedirectUrl() !== null) {
      window.location.replace(getRedirectUrl() ?? '');
    } else {
      setAuthState(SmsAuthState.Success);
    }
  };

  const onResendCode = () => {
    setAuthState(SmsAuthState.Start);
  };

  const onPhoneNumberEntered = (phoneNumberEntered: string) => {
    setPhoneNumber(phoneNumberEntered);
    setAuthState(SmsAuthState.Start);
  };

  useEffect(() => {
    if (authState === SmsAuthState.Start) {
      setAuthState(SmsAuthState.Sending);
      if (applicationId !== null) {
        sendSmsByApplicationId(applicationId).then(() => setAuthState(SmsAuthState.Sent));
      } else {
        sendSmsByPhoneNumber().then(() => setAuthState(SmsAuthState.Sent));
      }
    }
  }, [authState]);

  if (getRedirectUrl() === null || applicationId === null) {
    if (authState === SmsAuthState.WaitForUserClick) {
      return <EnterPhoneNumber onPhoneNumberEntered={onPhoneNumberEntered} />;
    }

    if (authState === SmsAuthState.Success) {
      return (
        <div className={styles.loaderContainer}>
          <StateContainer icon={<GreenCheck />} title="Success!" />
        </div>
      );
    }
  }

  if (authState === SmsAuthState.Start || authState === SmsAuthState.Sending) {
    return (
      <div className={styles.loaderContainer}>
        <StateContainer icon={<LoadingSpinner />} title="Sending verification code to your phone..." />
      </div>
    );
  }

  if (authState === SmsAuthState.WaitForUserClick) {
    return (
      <FormContainer
        title="Verify Your Identity"
        subtitle="To continue, let's verify it's really you. We will send you a code."
      >
        <Button className={styles.button} disabled={false} onClick={() => setAuthState(SmsAuthState.Start)}>
          Send Code To My Phone
        </Button>
      </FormContainer>
    );
  }

  return (
    <div className={styles.container}>
      <VerifySms onSuccessfulVerification={onSuccessfulVerification} onResendCode={onResendCode} />
    </div>
  );
};

export default SmsLogin;
