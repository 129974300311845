import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoanOfferApi } from 'api/LoanOfferApi';

import { ErrorResponse } from './ErrorResponse';

export const createVerifyApplicationThunk = (api: LoanOfferApi) =>
  createAsyncThunk('loanOffer/verifyApplication', async (applicationId: string, thunkApi) => {
    try {
      return await api.verifyApplication(applicationId);
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);
      throw error;
    }
  });
