import React, { useState } from 'react';
import FormContainer from 'components/LoanForm/FormContainer';
import OtpInput from 'components/OtpInput';

import { getAuthData } from 'selectors/getAuthData';
import { useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { authSmsCode } from 'thunks';
import Button from 'components/Button';
import { ReactComponent as WarningIcon } from 'images/warning-icon.svg';
import { ButtonType } from 'components/Button/Button';

import styles from './SmsLogin.module.scss';

export interface VerifySmsArgs {
  onSuccessfulVerification(): void;
  onResendCode(): void;
}

const VerifySms = (args: VerifySmsArgs) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [verificationCode, setVerificationCode] = useState('');
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [failedAuth, setFailedAuth] = useState(false);
  const { phoneId } = useSelector(getAuthData);

  const { onSuccessfulVerification, onResendCode } = args;

  function handleVerificationCodeChange(code: string) {
    setVerificationCode(code);
    if (code.trimRight().length === 6) {
      handleContinue(code);
    }
  }

  const handleContinue = async (code: string) => {
    if (isAuthenticating) {
      return;
    }
    setFailedAuth(false);
    setIsAuthenticating(true);
    try {
      const authResponse = await dispatchWithUnwrap(authSmsCode({ code, phoneId: phoneId ?? '' }));
      if (authResponse.verified) {
        onSuccessfulVerification();
        return;
      }
    } catch (error) {
      setIsAuthenticating(false);
    }
    setIsAuthenticating(false);
    setVerificationCode('');
    setFailedAuth(true);
  };

  return (
    <FormContainer
      title="Verify Your Identity"
      subtitle="Enter the 6-digit authorization code sent to phone number we have on file."
    >
      <OtpInput
        name="code"
        className={styles.formInput}
        onChange={(value) => handleVerificationCodeChange(value)}
        value={verificationCode}
        valueLength={6}
        disabled={isAuthenticating}
        autoFocus
      />
      {failedAuth && (
        <div className={styles.error}>
          <WarningIcon /> Invalid code, please check if you entered it correctly.
        </div>
      )}

      <div className={styles.buttonContainer}>
        <Button className={styles.button} disabled isLoading={isAuthenticating}>
          Continue
        </Button>

        <Button
          className={styles.button}
          disabled={isAuthenticating}
          onClick={() => onResendCode()}
          isLoading={isAuthenticating}
          type={ButtonType.Secondary}
        >
          Resend code
        </Button>
      </div>
    </FormContainer>
  );
};

export default VerifySms;
