import { createAsyncThunk } from '@reduxjs/toolkit';
import { PreQualificationApi } from 'api/PreQualificationApi';
import { PreQualificationData } from 'handlers/preQualificationData';
import { LocalStorageKeyName } from 'enums/LocalStorageKeyName';

import { ErrorResponse } from './ErrorResponse';

export const createGetPreQualificationDataThunk = (api: PreQualificationApi) =>
  createAsyncThunk('preQualification/getPreQualification', async (data: PreQualificationData, thunkApi) => {
    try {
      const preQualificationResult = await api.getPreQualificationResult(data);

      localStorage.setItem(LocalStorageKeyName.PreQualificationResult, JSON.stringify(preQualificationResult));

      return preQualificationResult;
    } catch (error: unknown) {
      thunkApi.rejectWithValue((error as ErrorResponse).response.data);

      throw error;
    }
  });
