import React, { ReactFragment } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { RoutePath } from 'enums/Routes';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { GetApplicationData } from 'handlers/applicationData';
import useLayoutTheme from 'hooks/useLayoutTheme';
import { VerificationStep, useVerificationSteps } from 'components/Verification/verificationSteps';
import { useApplySteps } from 'components/StudentLoanForgiveness/Apply/applySteps';
import { ApplyStep } from 'api/StudentLoanApi';

import Expander from 'components/Expander';

import styles from './CommonQuestions.module.scss';

interface Question {
  question: string;
  answer: ReactFragment;
}

const privacyPolicyLink = (
  <a href="https://www.planneryapp.com/privacy-policy" target="_blank" rel="noreferrer">
    Privacy Policy
  </a>
);

const infoSecure = {
  question: 'How do you keep my information secure?',
  answer: (
    <p>
      Our information is transferred and stored using the latest encryption standards. We do not sell your information,
      or share any information with third parties other than those required to provide you with our service (for
      example, looking up your credit report). To learn more, please read our {privacyPolicyLink}.
    </p>
  ),
};

const whatCreditScore = {
  question: 'What credit score will I need to be approved?',
  answer: (
    <p>
      We accept applicants with credit scores of 580 and above. Credit scores can vary depending on the bureau, and can
      change frequently. Your credit score might be higher than you think, so it doesn't hurt to apply and find out!
    </p>
  ),
};

const isCreditAffected = {
  question: 'Will applying affect my credit?',
  answer: <p>We do a soft pull to confirm your liabilities. Your score will not be affected.</p>,
};

const dependentsQuantity = {
  question: 'Who should I include as dependents?',
  answer: (
    <>
      <p>
        Includes your spouse if you filed your most recent federal tax return as married filing jointly and have not
        separated since you filed. (If you and your spouse filed taxes as married filing separately, your spouse is not
        included.)
      </p>

      <p>
        Include your children (including unborn children who will be born during this year) who receive more than half
        their support from you and are not included as a dependent for any other borrower, except for your spouse (if
        you filed federal taxes jointly with your spouse).
      </p>
    </>
  ),
};

const getQuestions = (
  path: RoutePath,
  {
    application,
    verificationStep,
  }: { application: GetApplicationData | undefined; verificationStep?: VerificationStep; applyStep?: ApplyStep },
): Question[] => {
  switch (path) {
    case RoutePath.Offer:
      return [
        {
          question: 'How long does it take to apply?',
          answer: (
            <p>Our application takes less than 5 minutes for you to complete and receive a pre-approved offer.</p>
          ),
        },
        {
          question: 'When do I get my loan money?',
          answer: (
            <p>
              After you sign the loan documents and set up your payment process, you could receive the funding within
              one to three business days.
            </p>
          ),
        },
        {
          question: 'How are you able to offer lower rates compared to other lenders?',
          answer: (
            <>
              <p>
                Unlike other lenders, we are solely focused on serving one community - healthcare professionals.
                Understanding the profile of healthcare professionals and addressing their needs distinguishes us from
                the competition.
              </p>
              <p>
                By taking repayments directly from your paycheck allows we are able to offer lower rates while helping
                you to maintain on-time payments.
              </p>
            </>
          ),
        },
        whatCreditScore,
        isCreditAffected,
      ];
    case RoutePath.YourName:
      return [infoSecure];
    case RoutePath.YourIncome:
      return [isCreditAffected, whatCreditScore, infoSecure];
    case RoutePath.ConfirmLoan:
      return [
        {
          question: 'When do I sign the final loan agreement?',
          answer: (
            <p>You will sign the final loan agreement after we verify your employment, and repayment is set up.</p>
          ),
        },
        {
          question: 'Why is there an origination fee for the loan?',
          answer: (
            <p>
              The origination fee covers our cost of offering you the loan. We add this fee on to the total loan amount,
              so you don’t have to pay anything upfront.
            </p>
          ),
        },
        {
          question: 'What fees do you charge?',
          answer: (
            <p>
              We charge an origination fee, and in certain states, there is a mandatory fee we must pay to the state.
              There is also a fee for missed payments, but we will set you up with automatic payments to avoid this. All
              of the fees are disclosed in the loan agreement.
            </p>
          ),
        },
        {
          question: 'How long is this offer valid for?',
          answer: (
            <p>
              This offer is valid for 30 days from the date that the offer was generated. After that time, you’ll have
              to reapply to get a new offer.
            </p>
          ),
        },
      ];
    case RoutePath.VerificationSteps:
      switch (verificationStep) {
        case VerificationStep.UploadPaystubs:
          return [
            {
              question: 'Why do I have to upload paystubs?',
              answer: (
                <p>
                  This helps us verify your employer, income, and pay period so you can receive the lowest interest
                  rates available and to help us.
                </p>
              ),
            },
            {
              question: 'How can I get my paystubs?',
              answer: (
                <>
                  <p>Log into your payroll system to download your paystubs.</p>
                  <p>
                    If you need help, click the ‘Need Help’ link at the bottom of the page to begin a text conversation
                    or feel call/text us at (404) 205-8882.
                  </p>
                </>
              ),
            },
          ];
        case VerificationStep.CheckingAccount:
          return [
            {
              question: 'Why do I have to do this?',
              answer: (
                <>
                  <p>
                    We ask you to connect your checking account for a few reasons: depending on your offer, we may have
                    to send funds directly to you so you can pay off the lenders on your behalf. Secondly, we use it as
                    a means to verify your income. Lastly, with your consent, this will be used to set up for automatic
                    payments to ensure your loan stays on track.
                  </p>
                </>
              ),
            },
            {
              question: 'What information are you retrieving?',
              answer: `We retrieve your account and routing number to ensure they are correct when we deposit funds. We also retrieve your recent transactions so we can check that you've been paid what your paystubs indicate.`,
            },
            {
              question: 'What is Plaid and why did we partner with them?',
              answer: (
                <>
                  Here are some resources you might find helpful:
                  <p>
                    <a href="https://plaid.com/how-it-works-for-consumers/" target="_blank" rel="noreferrer">
                      How Plaid Works
                    </a>
                  </p>
                  <p>
                    <a href="https://plaid.com/how-we-handle-data//" target="_blank" rel="noreferrer">
                      How Plaid Handles Data
                    </a>
                  </p>
                  <p>
                    <a href="https://plaid.com/why-is-plaid-involved/" target="_blank" rel="noreferrer">
                      Why is Plaid Involved
                    </a>
                  </p>
                </>
              ),
            },
          ];
        case VerificationStep.UploadLicense:
          return [
            {
              question: 'What is Alloy?',
              answer: (
                <>
                  <p>
                    Alloy helps banks and financial technology companies automate and manage their decisions for
                    onboarding, ongoing fraud & suspicious activity monitoring, and credit underwriting.
                  </p>
                  <p>
                    Alloy processes millions of daily identity decisions for the world’s leading financial institutions,
                    offering financial products in 40 countries.
                  </p>
                  <p>
                    Learn more about Alloy by visiting their{' '}
                    <a href="https://www.alloy.com/about" target="_blank" rel="noreferrer">
                      website
                    </a>
                    .
                  </p>
                </>
              ),
            },
          ];
        default:
          return [];
      }
    case RoutePath.RepaymentMethod:
      return [
        {
          question: 'Do I have to open this account to get the loan?',
          answer:
            'No. Having the direct deposit account makes it much easier to make payments for the loan. However, if you do not want to set this up, please call or text us at 877-643-3131.',
        },
        {
          question: 'Why can’t I use my existing bank account?',
          answer:
            'This bank account is created solely for the purpose of depositing and transferring payments for your Plannery loan. By having this as a separate bank account, we are able to set up the automation necessary to pay back the loan from your paycheck. If you do not want to set up this account, please call or text us at 877-643-3131.',
        },
        {
          question: 'Can I use this as a checking account?',
          answer:
            'No. This account is solely for the purpose of depositing and transferring payments for your Plannery loan. We don’t offer any other banking features at this time.',
        },
        {
          question: 'Are there any fees associated with the account?',
          answer:
            'Yes, there is a return-deposited item fee of $25, a paper statement fee (per statement) of $5 if requested or not opted into electronic statements. There is no minimum balance requirement. Since this account is solely for the purpose of depositing and transferring payments for your Plannery loan, the majority of the time your account will have zero balance.',
        },
        {
          question: 'Will I get statements?',
          answer: 'Yes. We will provide monthly account statements.',
        },
        {
          question: 'How will I be able to close the account in the future?',
          answer:
            'Your account will be closed automatically when your loan is fully repaid. You may also give us a call if you would like to close your account.',
        },
        {
          question: 'What happens if my employer changes?',
          answer:
            'If your employer changes, you will reconnect the loan to your new employer’s payroll system. We can also update your loan to match your new employer’s pay cycle.',
        },
        {
          question: 'What happens if I lose my job?',
          answer:
            'If payroll doesn’t run during a pay period, we will automatically deduct the money from your checking account that you provided earlier. We know life happens. If you are unable to pay, give us a call as soon as you can.',
        },
        ...(application?.fullDepositSwitch
          ? [
              {
                question: 'Will this change when I get access to my paycheck?',
                answer:
                  'Once your paycheck is deposited to your Plannery deposit account and your loan payment is debited, the remaining balance will be sent to your external checking account.  As long as cutoff times are met, you will have access to your money that same day.',
              },
            ]
          : []),
      ];
    case RoutePath.StudentLoanApplySteps:
      return [];
    case RoutePath.StudentLoanNonProfitStartDate:
      return [
        {
          question: 'What counts as full time?',
          answer:
            'This would include employment that consists of at least 30 hours of work per week at a non-profit or government organization.',
        },
      ];
    case RoutePath.StudentLoanDependentsQuantity:
      return [dependentsQuantity];
    default:
      return [];
  }
};

const CommonQuestions = () => {
  const location = useLocation();
  const { application } = useSelector(getApplicationData);
  const { themeStyles } = useLayoutTheme();
  const { currentStep: verificationStep } = useVerificationSteps();
  const { currentStep: applyStep } = useApplySteps();

  const questions = getQuestions(location.pathname as RoutePath, { application, verificationStep, applyStep });

  if (questions.length === 0) {
    return <></>;
  }

  return (
    <div className={clsx(styles.commonQuestions, themeStyles.commonQuestions)}>
      <h4 className={styles.heading}>Common questions:</h4>
      <div className={clsx(styles.questions, themeStyles.questions)}>
        {questions.map((question: Question) => (
          <Expander
            key={question.question}
            container={styles.expanderContainer}
            label={question.question}
            labelClassname={styles.label}
            content={question.answer}
            selectArrowClassname={clsx(styles.selectArrow, themeStyles.selectArrow)}
            openClassname={styles.expanderOpen}
          />
        ))}
      </div>
    </div>
  );
};

export default CommonQuestions;
