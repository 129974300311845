import React, { useEffect, useState } from 'react';

import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { ConversionType, trackConversion } from 'utils/analytics';
import { StudentLoanApplyOptionsResult } from 'enums/StudentLoanForgivenessFlowResults';
import CalendlyModal from 'components/StudentLoanForgiveness/Modal/CalendlyModal';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import AreYouSureModal from 'components/StudentLoanForgiveness/Modal/AreYouSureModal';
import { useSelector } from 'react-redux';
import { getPreQualificationData } from 'selectors/preQualificationData';
import { PartnerName } from 'enums/PartnerName';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { getStudentLoanApplication as getStudentLoanApplicationThunk } from 'thunks';

import ToggleButtonGroup from './components/ToggleButtonGroup';

import styles from './ApplyOptions.module.scss';

enum Result {
  ApplyWithUs = 'Apply with Us',
  ApplyOnYourOwn = 'Apply on Your Own',
  TalkToAnAdvisor = 'Talk to an Advisor',
}

const ApplyOptions = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const [isScheduleOpen, setScheduleIsOpen] = useState(false);
  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);
  const [selected, setSelected] = useState(Result.ApplyWithUs);
  const { partnerName } = useSelector(getPreQualificationData);

  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationId, knownEmployerProfitStatus } = useSelector(getStudentLoanData);
  const { fetched, isLoading } = useSelector(getStudentLoanApplication);
  const [clickedNext, setClickedNext] = useState(false);

  // The following code ensures that the application is loaded before advancing to the next step.
  useEffect(() => {
    applicationId &&
      (async () => {
        await dispatchWithUnwrap(getStudentLoanApplicationThunk({ applicationId }));
      })();
  }, []);

  useEffect(() => {
    if (fetched && clickedNext) {
      handleNext(StudentLoanApplyOptionsResult.ApplyWithUs);
    }
  }, [isLoading, clickedNext]);

  const onNext = () => {
    analytics.track('Student Loan Apply Option Selected', { selected });
    switch (selected) {
      case Result.TalkToAnAdvisor:
        setAreYouSureModalOpen(false);
        setScheduleIsOpen(true);
        break;
      case Result.ApplyOnYourOwn:
        handleNext(StudentLoanApplyOptionsResult.ApplyOnYourOwn);
        break;
      case Result.ApplyWithUs:
        trackConversion(ConversionType.StudentLoanApplyWithUs);
        setClickedNext(true);
        break;
      default:
    }
  };

  const onSchedule = () => {
    trackConversion(ConversionType.BookedStudentLoanMeeting);
    setScheduleIsOpen(false);
    handleNext(StudentLoanApplyOptionsResult.Schedule);
  };

  const handleNotNow = () => {
    analytics.track('Schedule Consultation Not Now Pressed');
    handleNext(StudentLoanApplyOptionsResult.NotNow);
  };

  return (
    <>
      <CalendlyModal open={isScheduleOpen} onClose={() => setScheduleIsOpen(false)} onSchedule={onSchedule} />
      {areYouSureModalOpen && (
        <AreYouSureModal
          onClose={() => setAreYouSureModalOpen(false)}
          onNext={() => setAreYouSureModalOpen(false)}
          onNotNow={handleNotNow}
          nextLabel="Continue Applying"
        />
      )}
      <FormNavigation {...navigationInfo} />
      <FormContainer
        video={
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video poster="/student-loan-video-poster.jpg" controls>
            <source src="https://assets.planneryapp.com/media/student-loan-advisor-1.webm" type="video/webm" />
          </video>
        }
        title="Let's apply together!"
      >
        <ToggleButtonGroup
          defaultValue={Result.ApplyWithUs}
          onChange={(value) => setSelected(value as Result)}
          options={[
            {
              value: Result.ApplyWithUs,
              title: 'Apply with Us',
              chip: 'Recommended',
              subtitle: 'Stress free – we’ll do the work for you.',
              children: (
                <ul className={styles.descriptionList}>
                  <li>An expert will review your information, apply and appeal on your behalf.</li>
                  <li>Guaranteed enrollment in the plans you qualify for.</li>
                </ul>
              ),
            },
            {
              value: Result.ApplyOnYourOwn,
              title: 'Apply on Your Own',
              subtitle: 'We’ll point you in the right direction.',
              children: (
                <ul className={styles.descriptionList}>
                  <li>
                    <span className={styles.cautionChip}>Caution</span> {knownEmployerProfitStatus ? '98% of' : 'Most'}{' '}
                    applicants applying on their own have been rejected.
                  </li>
                  <li>Most applicants do not hear back at all from their servicer or the government.</li>
                </ul>
              ),
            },
            ...(partnerName === PartnerName.Hillcrest
              ? [
                  {
                    value: Result.TalkToAnAdvisor,
                    title: 'Talk to an Advisor',
                    children: (
                      <>
                        <p className={styles.description}>
                          We offer 30 minute consultations to answer any questions and help you decide.
                        </p>
                      </>
                    ),
                  },
                ]
              : []),
          ]}
        />

        <div className={styles.buttonContainer}>
          <Button onClick={onNext} isLoading={clickedNext && isLoading}>
            Next
          </Button>

          <Button
            type={ButtonType.Transparent}
            onClick={() => setAreYouSureModalOpen(true)}
            isLoading={clickedNext && isLoading}
          >
            Not Now
          </Button>
        </div>
      </FormContainer>
    </>
  );
};

export default ApplyOptions;
